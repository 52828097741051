import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ContactPageIcon from '@mui/icons-material/ContactPage';

export const mainListItems = (
  <React.Fragment>
    <ListItemButton component="a" href="#">
      <ListItemIcon>
        <AttachMoneyIcon color="primary" />
      </ListItemIcon>
      <ListItemText primary="Pricing Calculator" />
    </ListItemButton>
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListItemButton component="a" href="#contact">
      <ListItemIcon>
        <ContactPageIcon color="primary" />
      </ListItemIcon>
      <ListItemText primary="Contact Us" />
    </ListItemButton>
  </React.Fragment>
);
