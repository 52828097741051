import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

const columns: GridColDef[] = [
  { field: 'model', headerName: 'Model', flex: 1 },
  { field: 'organization', headerName: 'Organization', flex: 1 },
  { field: 'input', headerName: 'Input $ / Million Tokens', type: 'number', flex: 1 },
  { field: 'output', headerName: 'Output $ / Million Tokens', type: 'number', flex: 1 },
  { field: 'updateDate', headerName: 'Last Updated Date', type: 'date', flex: 1 },
  { field: 'cost', headerName: 'Cost ($)', type: 'number', flex: 0.5,
    cellClassName: (params: GridCellParams<number>) => {
      return "super-app";
    },
  },
];

export default function Orders({inputTokens, outputTokens, dataRows}) {
  return (
    <React.Fragment>
      <Title>LLM Pricing</Title>
    <Box
      sx={{
        width: "100%",
        "& .super-app": {
           backgroundColor: "secondary.main",
        }
      }}
    >
      <DataGrid
        rows={dataRows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 50 },
          },
        }}
        disableRowSelectionOnClick={true}
      />
    </Box>
    </React.Fragment>
  );
}