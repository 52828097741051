import React, { useState } from "react";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems, secondaryListItems } from './listItems';
import Chart from './Chart';
import Deposits from './Deposits';
import Table from './Table';

const defaultCost = 1_000_000;
const staticRows = [
  { id: 1, model: 'Claude 3 Haiku', organization: 'Anthropic', input: 0.25, output: 1.25, updateDate: new Date('2024-04-20'), cost: 0 },
  { id: 2, model: 'Claude 3 Sonnet', organization: 'Anthropic', input: 3.0, output: 15.0, updateDate: new Date('2024-04-20'), cost: 0 },
  { id: 3, model: 'Claude 3 Opus', organization: 'Anthropic', input: 15.0, output: 75.0, updateDate: new Date('2024-04-20'), cost: 0 },
  { id: 4, model: 'Claude 3 2.1', organization: 'Anthropic', input: 8.0, output: 24.0, updateDate: new Date('2024-04-20'), cost: 0 },
  { id: 5, model: 'Claude 3 2.0', organization: 'Anthropic', input: 8.0, output: 24.0, updateDate: new Date('2024-04-20'), cost: 0 },
  { id: 6, model: 'Claude Instant', organization: 'Anthropic', input: 0.8, output: 2.4, updateDate: new Date('2024-04-20'), cost: 0 },
  { id: 7, model: 'GPT-4', organization: 'Open AI', input: 30.0, output: 60.0, updateDate: new Date('2024-04-20'), cost: 0 },
  { id: 8, model: 'GPT-4 Turbo (without vision pricing)', organization: 'Open AI', input: 10.0, output: 30.0, updateDate: new Date('2024-04-20'), cost: 0 },
  { id: 9, model: 'GPT-4-32k', organization: 'Open AI', input: 60.0, output: 120.0, updateDate: new Date('2024-04-20'), cost: 0 },
  { id: 10, model: 'GPT 3.5 Turbo', organization: 'Open AI', input: 0.5, output: 1.5, updateDate: new Date('2024-04-20'), cost: 0 },
  { id: 11, model: 'GPT 3.5 Turbo Instruct', organization: 'Open AI', input: 1.5, output: 2.0, updateDate: new Date('2024-04-20'), cost: 0 },
  { id: 12, model: 'Command R+', organization: 'Cohere', input: 3.0, output: 15.0, updateDate: new Date('2024-04-20'), cost: 0 },
  { id: 13, model: 'Command', organization: 'Cohere', input: 0.5, output: 1.5, updateDate: new Date('2024-04-20'), cost: 0 },
  { id: 14, model: 'mistral-small', organization: 'Mistral', input: 2.0, output: 6.0, updateDate: new Date('2024-04-20'), cost: 0 },
  { id: 15, model: 'mistral-large', organization: 'Mistral', input: 8.0, output: 24.0, updateDate: new Date('2024-04-20'), cost: 0 },
  { id: 16, model: 'open-mixtral-8x7b', organization: 'Mistral', input: 0.7, output: 0.7, updateDate: new Date('2024-04-20'), cost: 0 },
  { id: 17, model: 'open-mixtral-8x22b', organization: 'Mistral', input: 2.0, output: 6.0, updateDate: new Date('2024-04-20'), cost: 0 },
  { id: 18, model: 'Gemini 1.0 Pro', organization: 'Gemini', input: 0.5, output: 1.5, updateDate: new Date('2024-04-20'), cost: 0 },
  { id: 19, model: 'Gemini 1.5 Pro', organization: 'Gemini', input: 7.0, output: 21.0, updateDate: new Date('2024-04-20'), cost: 0 },
  { id: 20, model: 'DRBX On-Demand', organization: 'DataBricks', input: 2.25, output: 6.75, updateDate: new Date('2024-04-20'), cost: 0 },
  { id: 21, model: 'Llama 2 (via Bedrock On-Demand)', organization: 'Meta', input: 1.0, output: 2.56, updateDate: new Date('2024-04-20'), cost: 0 },
];

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

export default function Dashboard() {
  const [inputTokens, setInputTokens] = useState(0);
  const [outputTokens, setOutputTokens] = useState(0);
  const [dataRows, setDataRows] = useState(staticRows);

  function updateDataRowCost(inputTokens, outputTokens) {
    const updatedRows = staticRows.slice();
    for (let i = 0; i < updatedRows.length; i++) {
      updatedRows[i]['cost'] = updatedRows[i]['input'] * inputTokens / 1_000_000 + updatedRows[i]['output'] * outputTokens / 1_000_000;
    }
    setDataRows(updatedRows);
  }

  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            LLM Cost Advisor
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          {mainListItems}
          <Divider sx={{ my: 1 }} />
          {secondaryListItems}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={8} lg={9}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 240,
                }}
              >
                <Chart inputTokens={inputTokens} outputTokens={outputTokens} dataRows={dataRows} />
              </Paper>
            </Grid>
            {/* Recent Deposits */}
            <Grid item xs={12} md={4} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 240,
                }}
              >
                <Deposits inputTokens={inputTokens} setInputTokens={setInputTokens} outputTokens={outputTokens} setOutputTokens={setOutputTokens} setDataRows={updateDataRowCost} />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Table inputTokens={inputTokens} outputTokens={outputTokens} dataRows={dataRows} />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
