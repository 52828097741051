import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { ScatterChart } from '@mui/x-charts/ScatterChart';

import Title from './Title';

export default function Chart({inputTokens, outputTokens, dataRows}) {
  const theme = useTheme();

  const models = dataRows.map(row => ({
    label: row.organization + " " + row.model,
    data: [{x : row.input * inputTokens / 1_000_000, y: row.output * outputTokens / 1_000_000, id: row.model}]
  }));

  return (
    <React.Fragment>
      <Title>LLM Pricing By Organization</Title>
      <div style={{ width: '100%', flexGrow: 1, overflow: 'hidden' }}>
        <ScatterChart
          series={models}
          grid={{ vertical: true, horizontal: true }}
          slotProps={{
            legend: {
              hidden: true
            },
          }}
            xAxis={[
            {
              label: 'Input Token Cost ($)',
              valueFormatter: (value) => value >= 1_000_000 ? `${(value / 1000).toLocaleString()}k` : `${value}`,
            }
          ]}
          yAxis={[
            {
              label: 'Output Token\nCost ($)',
              valueFormatter: (value) => value >= 1_000_000 ? `${(value / 1000).toLocaleString()}k` : `${value}`,
            }
          ]}
        margin={{ left: 100 }}
        sx={
          {
                [`.${axisClasses.left} .${axisClasses.label}`]: {
                  // Move the y-axis label with CSS
                  transform: 'translateX(-35px)',
                },
              }
        }        />
      </div>
    </React.Fragment>
  );
}
