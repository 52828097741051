import React from "react";

import HelpIcon from '@mui/icons-material/Help'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';


export default function Deposits({inputTokens, setInputTokens, outputTokens, setOutputTokens, setDataRows}) {
  const theme = useTheme();

  const handleInputChange = (e) => {
    setInputTokens(e.currentTarget.value);
    setDataRows(e.currentTarget.value, outputTokens);
  }

  const handleOutputChange = (e) => {
    setOutputTokens(e.currentTarget.value);
    setDataRows(inputTokens, e.currentTarget.value);
  }

  return (
    <React.Fragment>
      <Grid xs="auto" item>
        <Box
          display="flex"
          justifyContent="flex-end"
          flexDirection="row"
        >
          <Tooltip title="Input your expected input and output token usage to visualize expected costs per LLM. Tokens are the basic data units processed by LLMs. A token can be word or a subset of characters for a provided word. A common rule of thumb is that one token generally corresponds to ~4 characters of English text. This translates to roughly ¾ of a word (so 100 tokens ~= 75 words)." placement="right-end">
            <HelpIcon color="primary" />
          </Tooltip>
        </Box>
      </Grid>
      <Grid xs={12} item>
        <Box
          height="100%"
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <TextField
            id="outlined-number"
            label="Input Tokens"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleInputChange}
          />
        </Box>
      </Grid>
      <Grid xs={12} item>
        <Box
          height="100%"
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <TextField
            id="outlined-number"
            label="Output Tokens"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleOutputChange}
          />
        </Box>
      </Grid>
    </React.Fragment>
  );
}
